import React from 'react';
import styled from 'styled-components';

interface ImageProps {
  src: string;
  isVisible: boolean;
  width: number;
  height: number;
}

interface StyledImageProps {}
const StyledImage = styled.img<StyledImageProps>`
  user-select: none;
  touch-action: none;
  cursor: inherit;
  -webkit-user-drag: none;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  inset: 0;
`;

const AnimationImage = ({ src, isVisible, width, height }: ImageProps) => {
  let d = isVisible ? 'block' : 'none';
  return (
    <StyledImage
      alt="Rotating object"
      src={src}
      width={width}
      height={height}
      style={{ display: `${d}` }}
      // style={{ opacity: isVisible ? 1 : 0 }}
    />
  );
};

export default AnimationImage;
