import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import HtmlRenderer from '../../components/common/Html';
import { device, useBreakpoint } from '../../utils';

const Wrapper = styled.div`
  margin-top: 70px;
  margin-bottom: 70px;

  &.dark {
    background-color: rgb(var(--tuto-polaroid-background-color));
    padding: 20px;
    color: #fff;
  }

  .video-wrapper {
    position: relative;
    width: 100%;
    text-align: center;
    margin: 20px auto;
    max-width: 100%;
    height: 0;
    padding-top: 100%;

    @media ${device.tablet} {
      // max-width: 90%;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      max-width: 100%;
      box-sizing: border-box;

      @media ${device.tablet} {
        border: 1px solid rgba(255, 255, 255, 0.5);
      }
    }
  }
`;

const VideoBlock = ({ title, text, darkMode, video, videoMobile }) => {
  const device = useBreakpoint();
  const isMobile = device === 'mobile';
  let currentVideo = video;

  if (isMobile && videoMobile?.html) {
    currentVideo = videoMobile;
  }

  return (
    <Wrapper
      className={classNames('container small', {
        dark: darkMode,
      })}
    >
      {!!title && (
        <h2 className={classNames('subtitle title-decoration')}>{title}</h2>
      )}
      <HtmlRenderer html={text?.html} />
      {!!currentVideo?.html && (
        <div
          className="video-wrapper"
          style={{
            paddingTop: `${((currentVideo?.height || 1) / (currentVideo?.width || 1)) * 100
              }%`,
          }}
          dangerouslySetInnerHTML={{
            __html: currentVideo?.html,
          }}
        />
      )}
    </Wrapper>
  );
};

export default VideoBlock;
