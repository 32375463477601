import React from 'react';
import cn from 'classnames';

import { nl2br, Imgix } from '../../utils';

import ContentSlice from './Content';
import ProductSlice from './Product';
import CarouselSlice from '../../components/slices/Carousel';

import FAQSlice from './FAQ';

import { Wrapper, Header, Slices } from './index.style';
import WideContent from './WideContent';
import VideoBlock from './VideoBlock';
import Chapter from './Chapter';
import Keypoints from './Keypoints';
import InfoBox from './InfoBox';
import Summary from './Summary';
import Polaroid from './Polaroid';

import Carousel from '../page/Carousel';
import Tuto from '../../components/slices/Tuto';
import { TutoBreadcrumb } from '../../components/common';

const imgResponsive = {
  xxs: 11,
  md: 5,
  contained: true,
};

const TutoScreen = ({ page }) => {
  return (
    <Wrapper>
      <div className="cover">
        <Imgix
          fadeIn={false}
          loading="eager"
          sizes="100vw"
          preloadHead
          breakpoints={{
            0: page.cover_mobile,
            760: page.cover,
          }}
        />
      </div>

      <div className="container small">
        <TutoBreadcrumb page={page} />
        {!!page.title && (
          <Header>
            <h1 className={cn('bigtitle', 'title-decoration')}>
              {nl2br(page.title)}
            </h1>
            {!!page.subtitle && <p>{page.subtitle}</p>}
          </Header>
        )}
      </div>
      <Slices>
        {page?.body.map((slice) => {
          if (slice.slice_type === 'content') {
            return (
              <ContentSlice
                key={slice.id}
                title={slice?.primary?.section_title}
                items={slice?.items}
              />
            );
          } else if (slice.slice_type === 'faq') {
            return <FAQSlice key={slice.id} sections={slice?.items} />;
          } else if (slice.slice_type === 'widecontent') {
            return (
              <WideContent
                key={slice.id}
                content={slice.primary.text}
                icon={slice.primary.icon}
                color={slice.primary.color}
                textColor={slice.primary.color_text}
                marginTop={slice.primary.margin_top}
                marginBottom={slice.primary.margin_bottom}
              />
            );
          } else if (slice.slice_type === 'polaroid') {
            console.log('--->', slice.primary);
            return (
              <Polaroid
                key={slice.id}
                content={slice.primary.content}
                color={slice.primary.color}
                cardColor={slice.primary.card_color}
                textColor={slice.primary.text_color}
                title={slice.primary.section_title}
                marginTop={slice.primary.margin_top}
                marginBottom={slice.primary.margin_bottom}
                isTurbo360={slice.primary.turbo_360}
              />
            );
          } else if (slice.slice_type === 'carousel') {
            return (
              <Carousel
                key={slice.id}
                className="container small carousel"
                displayGrid={slice.primary?.display_grid}
                title={slice.primary?.section_title}
                images={slice.items}
                responsive={imgResponsive}
              />
            );
          } else if (slice.slice_type === 'produits') {
            return (
              <ProductSlice
                key={slice.id}
                title={slice.primary.section_title}
                products={slice.items}
              />
            );
          } else if (slice.slice_type === 'video') {
            return (
              <VideoBlock
                key={slice.id}
                title={slice.primary.section_title}
                darkMode={slice.primary.dark_mode}
                text={slice.primary.text}
                video={slice.primary.video}
                videoMobile={slice.primary.video_mobile}
              />
            );
          } else if (slice.slice_type === 'slider') {
            return (
              <CarouselSlice
                key={slice.id}
                displayGrid={slice.primary?.display_grid}
                title={slice.primary?.title}
                darkMode={slice.primary?.darkmode}
                marginTop={slice.primary?.margin_top}
                marginBottom={slice.primary?.margin_bottom}
                items={slice.items}
                titleLevel="h2"
                className="carousel"
              />
            );
          } else if (slice.slice_type === 'tuto') {
            return (
              <Tuto
                key={slice.id}
                title={slice.primary?.title}
                darkMode={slice.primary?.darkmode}
                marginTop={slice.primary?.margin_top}
                marginBottom={slice.primary?.margin_bottom}
                items={slice.items}
                titleLevel="h2"
                className="carousel"
              />
            );
          } else if (slice.slice_type === 'chapter') {
            return (
              <Chapter
                key={slice.id}
                title={slice.primary?.section_title}
                text={slice.primary?.text}
                items={slice.items}
              />
            );
          } else if (slice.slice_type === 'keypoints') {
            return (
              <Keypoints
                key={slice.id}
                title={slice.primary?.section_title}
                backgroundColor={slice.primary?.background_color}
                color={slice.primary?.text_color}
                items={slice.items}
              />
            );
          } else if (slice.slice_type === 'infobox') {
            return (
              <InfoBox
                key={slice.id}
                title={slice.primary?.section_title}
                icon={slice.primary?.icon}
                backgroundColor={slice.primary?.background_color}
                color={slice.primary?.text_color}
                content={slice.primary?.text}
              />
            );
          } else if (slice.slice_type === 'summary') {
            return (
              <Summary
                key={slice.id}
                title={slice.primary.section_title}
                darkMode={slice.primary.dark_mode}
                text={slice.primary.text}
                items={slice.items}
              />
            );
          }

          return null;
        })}
      </Slices>
    </Wrapper>
  );
};

export default TutoScreen;
