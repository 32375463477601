import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import HtmlRenderer from '../../components/common/Html';
// import { Imgix } from '../../utils';
import { device } from '../../utils';

const Wrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;

  h3 {
    font-size: 18px;
    font-weight: normal;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .gatsby-image-wrapper {
    max-width: 100% !important;
  }

  ul {
    li {
      display: block;
      margin-bottom: 25px;

      strong {
        display: inline-block;
        font-size: 18px;
        margin-bottom: 5px;
      }
    }
  }

  .blocks {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;

    @media ${device.tablet} {
      grid-gap: 20px;
      grid-template-columns: 1fr 1fr;

      .content {
        &:not(.col) {
          grid-column: 1 / span 2;
        }
      }
    }
  }

  .content {
    p + .gatsby-image-wrapper {
      margin-top: -15px;
    }
  }

  .html-renderer {
    & > :last-child {
      margin-bottom: 0;

      *:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const Content = ({ title, items = [] }) => {
  return (
    <Wrapper className="container small">
      {!!title && <h2 className="subtitle title-decoration">{title}</h2>}
      <div className="blocks">
        {items?.map((item, key) => (
          <div
            key={key}
            className={classNames('content', {
              col: item.is_column,
            })}
          >
            <HtmlRenderer
              html={item?.content?.html}
              className="html-renderer"
              maxWidth={640}
            />
          </div>
        ))}
      </div>
    </Wrapper>
  );
};

export default Content;
