import React from 'react';
import styled from 'styled-components';

import { ProductItem } from '../../components/eshop';
import { device } from '../../utils';

const Wrapper = styled.div`
  margin: 50px auto;

  h2 {
    font-weight: 900;
    font-size: 20px;
  }

  @media ${device.laptop} {
    .product-grid {
      grid-gap: 40px;
    }
  }
`;

const ProductContent = ({ title, products }) => {
  if (!products?.length) {
    return null;
  }

  return (
    <Wrapper className="container small">
      {title ? <h2>{title}</h2> : null}
      <div className="product-grid three">
        {products.map((product) => (
          <ProductItem
            key={product.id}
            {...product}
            noSEO
            displayFrom={
              (product.options || []).reduce(
                (total, i) => total + i.values?.length || 0,
                0
              ) > 1
            }
          />
        ))}
      </div>
    </Wrapper>
  );
};

export default ProductContent;
