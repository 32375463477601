import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { useLocale } from '../../services/i18n/useLocale';
import Carousel from "./Carousel";

const Tuto = ({
  title,
  darkMode = false,
  marginTop,
  marginBottom,
  titleLevel,
  titleClassName
}) => {
  const intl = useLocale();
  const data = useStaticQuery(graphql`
    query TutoList {
      items: allPrismicTuto(
        sort: {fields: first_publication_date, order: DESC}
        filter: {data: {thumbnail: {url: {ne: null}}}}
      ) {
        nodes {
          data {
            thumbnail {
              alt
              dimensions {
                height
                width
              }
              url
            }
          }
          url
          lang
        }
      }
    }
  `);

  const items = data.items.nodes
    .filter(i => i.lang.toLowerCase().indexOf(intl.locale.toLowerCase()) !== -1)
    .map(i => ({
      image: i.data.thumbnail,
      link: { url: i.url }
    }));

  return (
    <Carousel
      title={title}
      darkMode={darkMode}
      items={items}
      marginTop={marginTop}
      marginBottom={marginBottom}
      titleLevel={titleLevel}
      titleClassName={titleClassName}
    />
  );
};

export default Tuto;