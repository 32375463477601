import React from 'react';
import { graphql } from 'gatsby';
import Seo from '../../components/seo';

import TutoScreen from '../../screens/tuto';
import { oneMatch, Product, stripHtml } from '../../utils';
import usePreviewData from '../../utils/usePreviewData';
import { trackProductList } from '../../utils/gtm';
import { useLocale } from '../../services/i18n/useLocale';
import { Helmet } from 'react-helmet';

const PageTemplate = ({ data: rawData, pageContext }) => {
  const data = usePreviewData(rawData);
  const intl = useLocale();

  const page = data?.prismicTuto?.data;

  const pageTitle = page.title?.text;

  const products = (data?.products?.nodes || []).map((p) =>
    Product(p, intl.locale)
  );
  const displayedProducts = [];

  const content = (page?.body || []).map((slice) => {
    if (slice.slice_type === 'carousel') {
      return {
        ...slice,
        items: (slice.items || []).map((i) => i.image).filter((i) => i),
      };
    }

    if (slice.slice_type === 'produits') {
      return {
        ...slice,
        items: (slice.items || [])
          .map((i) => {
            const current = products.find((p) =>
              oneMatch(
                p.skus,
                i.product?.variants?.map((v) => v.sku)
              )
            );
            if (!current) {
              return null;
            }
            const result = {
              ...current,
              trackList: 'PageV2',
              trackPosition: displayedProducts.length + 1,
            };

            displayedProducts.push(result);
            return result;
          })
          .filter((p) => p),
      };
    }

    return slice;
  });

  trackProductList(
    displayedProducts.map((p) => ({
      sku: p.defaultVariant?.sku,
      name: p.title,
      price: p.defaultVariant?.price,
      variant: p.defaultVariant?.title,
      category: p.productType,
      list: p.trackList,
      position: p.trackPosition,
    }))
  );

  const firstText = (page?.body || []).find((s) => s.slice_type === 'contenu');
  const firstTextHtml = page.subtitle || firstText?.primary?.content?.html;
  const globalDarkMode = !!data.prismicTuto?.data?.global_dark_mode;

  return (
    <>
      <Seo
        title={pageTitle}
        description={data.prismicTuto?.data?.seo_description || stripHtml(firstTextHtml || '')}
        image={page?.cover?.url}
      />
      {globalDarkMode && (
        <Helmet
          bodyAttributes={{
            darkmode: globalDarkMode ? true : undefined
          }}
        />
      )}
      <TutoScreen
        page={{
          ...page,
          uid: data?.prismicTuto?.uid,
          tags: data?.prismicTuto?.tags,
          title: pageTitle,
          body: content,
        }}
        share={!!content.share}
      />
    </>
  );
};

export const query = graphql`
  query TutoHome(
    $prismic: ID!
    $locale: String!
    # $cmsLocale: String!,
    $fetchProducts: [String!]
  ) {
    prismicTuto(prismicId: { eq: $prismic }) {
      tags
      uid
      data {
        title {
          text
        }
        seo_description
        subtitle
        global_dark_mode
        cover {
          alt
          url
          dimensions {
            height
            width
          }
        }
        cover_mobile {
          alt
          url
          dimensions {
            height
            width
          }
        }
        body {
          ... on PrismicTutoDataBodySlider {
            id
            slice_type
            primary {
              title
              darkmode
              margin_top
              margin_bottom
            }
            items {
              title
              link {
                url
                target
              }
              image {
                url
                dimensions {
                  height
                  width
                }
                alt
              }
            }
          }
          ... on PrismicTutoDataBodyCarousel {
            id
            slice_type
            primary {
              display_grid
              section_title
            }
            items {
              image {
                alt
                dimensions {
                  height
                  width
                }
                url
              }
            }
          }
          ... on PrismicTutoDataBodyProduits {
            id
            primary {
              section_title
            }
            items {
              product {
                id
                variants {
                  sku
                }
              }
            }
            slice_type
          }
          ... on PrismicTutoDataBodyWidecontent {
            id
            primary {
              color
              color_text
              margin_top
              margin_bottom
              text {
                html
              }
              icon {
                alt
                fixed(height: 80, width: 80, imgixParams: { fit: "max" }) {
                  height
                  width
                  src
                  srcSet
                }
              }
            }
            slice_type
          }
          ... on PrismicTutoDataBodyPolaroid {
            id
            primary {
              color
              card_color
              text_color
              margin_top
              margin_bottom
              turbo_360
              section_title
              content {
                html
              }
            }
            slice_type
          }
          ... on PrismicTutoDataBodyVideo {
            id
            slice_label
            slice_type
            primary {
              dark_mode
              section_title
              text {
                html
              }
              video {
                width
                height
                html
              }
              video_mobile {
                html
                width
                height
              }
            }
          }
          ... on PrismicTutoDataBodyTuto {
            id
            slice_type
            primary {
              darkmode
              title
              margin_top
              margin_bottom
            }
          }
          ... on PrismicTutoDataBodyChapter {
            id
            slice_type
            primary {
              section_title
              text {
                html
              }
            }
            items {
              subtitle
              zoomable
              text {
                html
              }
              image {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
            }
          }
          ... on PrismicTutoDataBodyKeypoints {
            id
            slice_type
            primary {
              section_title
              background_color
              text_color
            }
            items {
              text {
                html
              }
              icon {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
            }
          }
          ... on PrismicTutoDataBodyContent {
            id
            slice_type
            primary {
              section_title
            }
            items {
              is_column
              content {
                html
              }
            }
          }
          ... on PrismicTutoDataBodyInfobox {
            id
            slice_type
            primary {
              section_title
              background_color
              text_color
              icon {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
              text {
                html
              }
            }
          }
          ... on PrismicTutoDataBodySummary {
            id
            slice_type
            primary {
              section_title
              text {
                html
              }
              dark_mode
            }
            items {
              icon {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
              text {
                html
              }
            }
          }
        }
      }
    }
    products: allShopifyProduct(
      filter: { skus: { in: $fetchProducts }, locale: { eq: $locale } }
    ) {
      nodes {
        shopifyAdminId
        ...ProductItem
        priceRange {
          minVariantPrice {
            amount
            currencyCode
          }
        }
        options {
          name
          values
        }
      }
    }
  }
`;
export default PageTemplate;
