import React from 'react';
import styled from 'styled-components';
import HtmlRenderer from '../../components/common/Html';
// import { Imgix } from '../../utils';
import { device, Imgix, nl2br } from '../../utils';

const Wrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;

  .background {
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  h3 {
    font-size: 18px;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .grid {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
    margin: 30px 0 15px;
    grid-gap: 40px 0;

    @media ${device.laptop} {
      grid-template-columns: repeat(auto-fit, minmax(70px, 150px));
    }
  }

  .item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 0px 20px;

    &:nth-child(odd) {
      border-right: 1px solid #fff;
    }

    @media ${device.laptop} {
      padding: 0 10%;
      border-right: 1px solid #fff;

      &:last-child {
        border: none;
      }
    }
  }

  .content {
    p {
      font-weight: bold;
      font-size: 18px;
      margin: 0;
      line-height: 1.2em;
    }
  }
`;

const Keypoints = ({ title, backgroundColor, color, items = [] }) => {
  return (
    <Wrapper className="container small">
      <div
        className="background"
        style={{
          backgroundColor,
          color,
        }}
      >
        {!!title && <h2 className="subtitle ">{nl2br(title)}</h2>}
        <div className="grid">
          {items?.map((item, key) => (
            <div key={key} className="item">
              <div className="icon">
                {!!item.icon?.url && (
                  <Imgix
                    {...item.icon}
                    width={65}
                    className="icon-image"
                    imgStyle={{
                      objectFit: 'contain',
                    }}
                  />
                )}
              </div>
              <div className="content">
                {item.subtitle && <h3>{item.subtitle}</h3>}
                <HtmlRenderer html={item?.text?.html} maxWidth={640} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Wrapper>
  );
};

export default Keypoints;
