import React from 'react';
import styled from 'styled-components';
import HtmlRenderer from '../../components/common/Html';
// import { Imgix } from '../../utils';
import { Imgix } from '../../utils';

const Wrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;

  .background {
    padding: 20px 30px 30px;
  }

  .header {
    display: flex;
    flex-direction: row;

    .icon-wrapper {
      margin-right: 10px;
    }
  }

  .icon-wrapper {
    text-align: center;
    margin-bottom: 10px;
    margin-left: -10px;
  }

  p {
    font-size: 16px;
  }
`;

const InfoBox = ({ title, content, icon, backgroundColor, color }) => {
  return (
    <Wrapper className="container small">
      <div
        className="background"
        style={{
          color,
          backgroundColor,
        }}
      >
        <div className="header">
          {!!icon?.url && (
            <div className="icon-wrapper">
              <Imgix
                {...icon}
                className="icon-image"
                height={50}
                imgStyle={{
                  objectFit: 'contain',
                }}
              />
            </div>
          )}
          {!!title && <h2 className="subtitle">{title}</h2>}
        </div>
        <HtmlRenderer html={content?.html} />
      </div>
    </Wrapper>
  );
};

export default InfoBox;
