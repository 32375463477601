import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Controlled as ControlledZoom } from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

import HtmlRenderer from '../../components/common/Html';
// import { Imgix } from '../../utils';
import { device, Imgix } from '../../utils';

const Wrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;

  .gatsby-image-wrapper {
    width: 100%;
  }

  h3 {
    font-size: 18px;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .item {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px;
    margin: 15px 0;

    @media ${device.tabletDown} {
      &:first-child {
        h3 {
          margin-top: 0;
        }
      }
    }

    @media ${device.tablet} {
      /*grid-gap: 35px;
      grid-template-columns: 1fr 1fr;

      &:not(.reverse) {
        .image {
          grid-column: 1;
          grid-row: 1;
        }

        .content {
          grid-row: 1;
          grid-column: 2;
        }
      }*/
    }
  }
`;

const imgResponsive = {
  xxs: 11,
  md: 5,
  contained: true,
};

const zoomedResponsive = {
  xxs: 12,
};

const ZoomedImage = ({ image, responsive }) => {
  const [isZoomed, setIsZoomed] = useState(false);

  const handleZoomChange = useCallback((shouldZoom) => {
    setIsZoomed(shouldZoom);
  }, []);

  return (
    <ControlledZoom
      isZoomed={isZoomed}
      onZoomChange={handleZoomChange}
      wrapStyle={{
        width: '100%',
      }}
      zoomMargin={20}
    >
      <Imgix
        {...image}
        sizes={isZoomed ? zoomedResponsive : responsive}
        imgStyle={{
          objectFit: 'contain',
        }}
      />
    </ControlledZoom>
  );
};

const Chapter = ({ title, text, items = [] }) => {
  return (
    <Wrapper className="container small">
      {!!title && <h2 className="subtitle title-decoration">{title}</h2>}
      {/* {!!icon?.fixed?.src && (
        <div className="icon-wrapper">
          <Image
            {...icon}
            imgStyle={{
              objectFit: 'contain',
            }}
          />
        </div>
      )} */}
      {!!text?.html && <HtmlRenderer html={text?.html} />}
      <div className="lines">
        {items?.map((item, key) => (
          <div
            key={key}
            className={classNames('item', {
              reverse: item.reverse,
            })}
          >
            <div className="content">
              {item.subtitle && <h3>{item.subtitle}</h3>}
              <HtmlRenderer html={item?.text?.html} maxWidth={640} />
            </div>
            <div className="image">
              {!!item.image?.url && item.zoomable && (
                <ZoomedImage image={item.image} responsive={imgResponsive} />
              )}
              {!!item.image?.url && !item.zoomable && (
                <Imgix
                  {...item.image}
                  sizes={imgResponsive}
                  imgStyle={{
                    objectFit: 'contain',
                  }}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </Wrapper>
  );
};

export default Chapter;
