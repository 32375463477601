import styled from 'styled-components';

import { device } from '../../utils';

export const Wrapper = styled.article`
  width: 100%;
  display: block;

  .grey {
    color: #8b8c91;
  }

  .bigtitle {
  }

  .container.small {
    // max-width: 980px;
    // max-width: 800px;
    max-width: 680px;
  }

  /*.gatsby-image-wrapper:not(.icon-image) {
    margin-left: -30px;
    margin-right: -30px;
    width: calc(100% + 60px);
    max-width: none !important;
  }*/

  .subtitle {
    font-weight: 900;
    font-size: 20px;
    line-height: 1.3em;
  }

  .title-decoration {
    &:after {
      content: '';
      display: block;
      height: 2px;
      width: 70px;
      background-color: currentColor;
      margin-top: 10px;
    }
  }

  /* .carousel {
    margin-top: 0;
    margin-bottom: 0;
  } */

  .small-title {
    font-size: 17px;
    font-weight: 500;
    line-height: 35px;
    margin: 20px 0 10px;
  }

  .cover {
    position: relative;
    background-color: #e9e9e9;

    .image {
      & > div {
        @media ${device.tablet} {
          padding-bottom: 31.25% !important;
        }
      }
    }
  }

  .breadcrumb {
    position: absolute;
    top: 0;
    width: 100%;
  }

  .center {
    text-align: center;
  }
`;

export const Header = styled.header`
  margin-top: 50px;
  margin-bottom: 20px;

  p {
    font-weight: bold;
    font-size: 20px;
    margin: 30px 0 20px;
  }
`;

export const Slices = styled.div`
  & > :last-child {
    margin-bottom: 0;
  }
`;
