import React from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from 'swiper';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

import { Imgix } from '../../utils';

const modules = [Pagination, Navigation];

const pagination = {
  clickable: true,
};

const Wrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 30px;

  [data-rmiz-wrap] {
    width: 100%;
  }

  * {
    outline: none;
  }

  .swiper {
    padding-bottom: 50px;
  }

  .swiper-wrapper {
    align-items: center;
  }

  .swiper-pagination-bullet {
    --swiper-pagination-bullet-width: 10px;
    --swiper-pagination-bullet-height: 10px;
    --swiper-pagination-color: #c80f05;
  }

  .swiper-button-next {
    &:after {
      content: "→";
    }
  }
  .swiper-button-prev {
    &:after {
      content: "←";
    }
  }
  .swiper-button-next, .swiper-button-prev {
    transform: translateY(-50%);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #fff;
    width: 38px;
    height: 38px;
    background-color: rgba(0, 0, 0, 0.4);
    transition: background-color 300ms;
    padding: 0;

    &:hover {
      background-color: rgba(0, 0, 0, 0.6);
    }

    &.swiper-button-disabled {
      display: none;
    }

    &:after {
      color: #FFF;
      font-family: sans-serif;
      font-size: 22px;
    }
  }
`;

// const hideControls = () => null;


const DisplayGrid = ({ images }) => (
  <div className="product-grid three">
    {images.map((image, idx) => (
      <Imgix key={idx} alt="Ipone" {...image} />
    ))}
  </div>
);

const DisplayCarousel = ({ images, responsive }) => (
  <Swiper pagination={pagination} navigation modules={modules}>
    {images.map((image, idx) => (
      <SwiperSlide key={idx}>
        <Imgix key={idx} alt="Ipone" {...image} sizes={responsive} />
      </SwiperSlide>
    ))}
  </Swiper>
);

const CarouselContent = ({
  className,
  title,
  images,
  displayGrid,
  responsive,
}) => {
  if (!images?.length) {
    return null;
  }

  return (
    <Wrapper className={className}>
      {title ? <h2>{title}</h2> : null}
      {displayGrid ? (
        <DisplayGrid images={images} />
      ) : (
        <DisplayCarousel images={images} responsive={responsive} />
      )}
    </Wrapper>
  );
};

export default CarouselContent;
