import React from 'react';
import styled from 'styled-components';
import HtmlRenderer from '../../components/common/Html';
// import { Imgix } from '../../utils';
import Image from 'gatsby-image';
import classNames from 'classnames';
import { device } from '../../utils';

const Wrapper = styled.div`
  padding: 50px 0;

  &.mt-small {
    padding-top: 25px;
  }

  &.mb-small {
    padding-bottom: 25px;
  }

  &.mt-none {
    padding-top: 0;
  }

  &.mb-none {
    padding-bottom: 0;
  }

  .icon-wrapper {
    text-align: center;
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;

    @media ${device.tablet} {
      font-size: 18px;
    }

    .right-align {
      display: block;
      text-align: right;
    }

    .center-align {
      display: block;
      text-align: center;
    }
  }

  h2,
  h3,
  h4 {
    .center-align {
      display: block;
      text-align: center;
    }
  }

  h3 {
    font-weight: 700;
    font-size: 24px;
  }

  .content {
    > *:first-child {
      margin-top: 0;
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }
`;

const WideContent = ({
  content,
  icon,
  color,
  textColor,
  marginTop,
  marginBottom,
}) => {
  if (!content && !icon) {
    return null;
  }

  return (
    <Wrapper
      style={{ backgroundColor: color, color: textColor }}
      className={classNames({
        [`mt-${marginTop}`]: marginTop,
        [`mb-${marginBottom}`]: marginBottom,
      })}
    >
      <div className="container small">
        {!!icon?.fixed?.src && (
          <div className="icon-wrapper">
            <Image
              {...icon}
              className="icon-image"
              imgStyle={{
                objectFit: 'contain',
              }}
            />
          </div>
        )}
        <HtmlRenderer html={content?.html} className="content" maxWidth={640} />
      </div>
    </Wrapper>
  );
};

export default WideContent;
