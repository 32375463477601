import React from 'react';
// import PropTypes from 'prop-types';
// import SplitText from 'react-pose-text';
// import VisibilitySensor from 'react-intersection-visible';
import classnames from 'classnames';
// import { isMobile } from 'react-device-detect';

class Title extends React.PureComponent {
  // state = {
  //   visible: false,
  // }

  // onVisible = () => {
  //   // console.log('Element %s is now visible', this.props.title);
  //   this.setState({
  //     visible: true,
  //   });
  // }

  render = () => {
    // const { visible } = this.state;
    // const { title, anim, className } = this.props;
    const { title, className, as = "h2" } = this.props;
    const MarkupTag = as;

    // if (!anim || isMobile) {
    return (
      <MarkupTag className={classnames('title bigtitle', className)}>
        {title.split('<br />').map((text) => (
          <div
            key={text}
            className="line"
          >
            {text.trim()}
          </div>
        ))}
      </MarkupTag>
    );
    // }

    // const charPoses = {
    //   initial: { opacity: 0.2, y: 0 },
    //   stale: { opacity: 0.2, y: 0 },
    //   animate: {
    //     opacity: 1,
    //     y: 0,
    //     delay: ({ charIndex }) => charIndex * 80
    //   }
    // };

    // return (
    //   <h2
    //     className={classnames('title bigtitle', className)}
    //   >
    //     <VisibilitySensor
    //       onShow={this.onVisible}
    //     >
    //       {title.split('<br />').map((text) => (
    //         <div
    //           key={text}  
    //           className="line"
    //         >
    //           <SplitText
    //             initialPose="initial"
    //             pose={visible ? 'animate' : 'stale'}
    //             charPoses={charPoses}
    //           >
    //             {text.trim()}
    //           </SplitText>
    //         </div>
    //       ))}
    //     </VisibilitySensor>
    //   </h2>
    // )
  };
}

// Title.defaultProps = {
//   anim: true,
// }

// Title.propTypes = {
//   title: PropTypes.string,
//   anim: PropTypes.bool,
// }

export default Title;
