import React from 'react';
import { Wrapper } from './Moto360.styles';
import { React360Viewer } from '../../../components/spinviewer';


/*baseline, products, */
const Moto360 = ({ }) => {
  return (
    <Wrapper>
      {/* <ThreeSixty
        amount={33}
        imagePath={"/turbo/360/"}
        fileName="{index}.webp"
        autoplay
      /> */}
      <React360Viewer
        imagesBaseUrl="/turbo/360/"
        imagesCount={33}
        imagesFiletype="webp"
        mouseDragSpeed={20}
      />
    </Wrapper>
  );
};

export default Moto360;
