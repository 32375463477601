import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import HtmlRenderer from '../../components/common/Html';
import { device, Imgix } from '../../utils';

const Wrapper = styled.div`
  margin-top: 70px;
  margin-bottom: 70px;

  &.dark {
    .background {
      background-color: rgb(var(--tuto-polaroid-background-color));
      padding: 20px;
      color: #fff;
    }
  }

  .grid {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr;
    margin: 30px -20px 15px -20px;
    grid-gap: 40px 0;

    @media ${device.laptop} {
      grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
    }
  }

  .item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding: 0px 20px;
  }

  .icon {
    margin-bottom: 10px;
  }

  .content {
    p {
      font-weight: bold;
      font-size: 18px;
      margin: 0;
      line-height: 1.2em;
      text-align: center;
    }
  }
`;

const Summary = ({ title, text, darkMode, items = [] }) => {
  return (
    <Wrapper
      className={classNames('container small', {
        dark: darkMode,
      })}
    >
      <div className="background">
        {!!title && (
          <h2 className={classNames('subtitle title-decoration')}>{title}</h2>
        )}
        <HtmlRenderer html={text?.html} />
        <div className="grid">
          {items?.map((item, key) => (
            <div key={key} className="item">
              <div className="icon">
                {!!item.icon?.url && (
                  <Imgix
                    {...item.icon}
                    height={50}
                    className="icon-image"
                    imgStyle={{
                      objectFit: 'contain',
                    }}
                  />
                )}
              </div>
              <div className="content">
                <HtmlRenderer html={item?.text?.html} maxWidth={640} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Wrapper>
  );
};

export default Summary;
