import React, { useRef } from 'react';
// import CarouselCore from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, FreeMode } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';

import cn from 'classnames';

import { Imgix, isSafariBrowser } from '../../utils';
import Title from '../../components/title';
import ProductItem from '../../components/eshop/ProductItem';
import MagicLink from '../../components/link';

import useIntersection from '../../utils/useIntersection';

import { Wrapper, SlideWrapper } from './Carousel.styles';
import classNames from 'classnames';
import CarouselAnimation from './CarouselAnimation';

const modules = [Navigation, FreeMode];

const visibilityThreshold = [0, 1];

const imgResponsive = {
  xxs: 9,
  md: 4,
  contained: true,
};

const Slide = ({ className = '', title, image, link, loading }) => {
  const isSafari = isSafariBrowser();
  return (
    <SlideWrapper className={className} role="presentation">
      <MagicLink {...link}>
        <Imgix
          {...image}
          alt={image.alt || title}
          sizes={imgResponsive}
          loading={loading}
          fadeIn={false}
          key={isSafari ? `force-redraw-${loading}` : 'no-force-redraw'}
          imgStyle={{
            objectFit: 'contain',
            objectPosition: 'bottom center',
          }}
        />
        {title && <div className="title">{title}</div>}
      </MagicLink>
    </SlideWrapper>
  );
};

const productImgSize =
  '(max-width: 500px) 80vw, (max-width: 1440px) 45vw, 450px';

const breakpoints = {
  0: {
    slidesPerView: 'auto',
    slidesOffsetBefore: 20,
    slidesOffsetAfter: 20,
    spaceBetween: 0,
  },
  1440: {
    slidesPerView: 3,
    slidesOffsetBefore: 0,
    slidesOffsetAfter: 0,
    spaceBetween: 16,
  },
};

const Carousel = ({
  items,
  title,
  isProduct = false,
  titleLevel,
  titleClassName,
  className,
  marginTop,
  marginBottom,
  darkMode = false,
}) => {
  // const hasAnimation = title?.toLowerCase() === 'Nos huiles moteur'.toLowerCase();

  const ref = useRef(null);
  const intersection = useIntersection(
    ref,
    {
      root: null,
      rootMargin: '0px',
      threshold: visibilityThreshold,
    },
    true
  );
  const isLoaded = !!intersection?.isIntersecting;

  return (
    <Wrapper
      className={classNames(className, {
        dark: darkMode,
        [`mt-${marginTop}`]: marginTop,
        [`mb-${marginBottom}`]: marginBottom,
        // turbo700: hasAnimation
      })}
    >
      {title && (
        <div className="container">
          <Title title={title} as={titleLevel} className={titleClassName} />
        </div>
      )}

      <div
        className={cn('container slider-container', {
          product: isProduct,
        })}
        ref={ref}
      >
        <Swiper
          modules={modules}
          slidesPerView="auto"
          freeMode
          navigation
          enabled
          // freeModeMomentumBounceRatio={1}
          // freeModeMomentumRatio={.85}
          // freeModeMomentumVelocityRatio={.85}
          watchSlidesProgress
          breakpoints={breakpoints}
          slidesOffsetBefore={20}
          slidesOffsetAfter={20}
          // autoHeight
          preloadImages={false}
        >
          {(items || []).map((item, idx) => {
            if (item.shopifyAdminId) {
              return (
                <SwiperSlide key={item.id || idx} className="product-item">
                  <ProductItem
                    {...item}
                    largeDisplay
                    customSizes={productImgSize}
                    imageLoading={isLoaded ? 'eager' : 'lazy'}
                  />
                </SwiperSlide>
              );
            }

            return (
              <SwiperSlide key={item.title || idx}>
                <Slide {...item} loading={isLoaded ? 'eager' : 'lazy'} />
              </SwiperSlide>
            );
          })}
        </Swiper>
        {/* {!!hasAnimation && (
          <CarouselAnimation elementRef={ref} />
        )} */}
      </div>
    </Wrapper>
  );
};

export default Carousel;
