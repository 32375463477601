import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import HtmlRenderer from '../../components/common/Html';
import { device } from '../../utils';
import Moto360 from '../homepage/Moto360/Moto360';
// import { Imgix } from '../../utils';

const Wrapper = styled.div`
  padding: 50px 20px;

  &.mt-small {
    padding-top: 25px;
  }

  &.mb-small {
    padding-bottom: 25px;
  }

  &.mt-none {
    padding-top: 0px;
  }

  &.mb-none {
    padding-bottom: 0px;
  }

  @media ${device.tablet} {
    padding-left: 0;
    padding-right: 0;
  }

  .container {
    background: #fff;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .subtitle {
    margin-top: 0;
  }

  p {
    font-size: 14px;
    line-height: 24px;

    @media ${device.tablet} {
      font-size: 16px;
    }

    .right-align {
      display: block;
      text-align: right;
    }

    .center-align {
      display: block;
      text-align: center;
    }
  }

  h2,
  h3,
  h4 {
    .center-align {
      display: block;
      text-align: center;
    }
  }

  h3 {
    font-weight: 700;
    font-size: 24px;
  }

  .content {
    > *:first-child {
      margin-top: 0;
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }
`;

const Polaroid = ({
  content,
  color,
  cardColor,
  textColor,
  title,
  marginTop,
  marginBottom,
  isTurbo360
}) => {
  return (
    <Wrapper
      style={{ backgroundColor: color }}
      className={classNames({
        [`mt-${marginTop}`]: marginTop,
        [`mb-${marginBottom}`]: marginBottom,
      })}
    >
      <div
        className="container small"
        style={{ backgroundColor: cardColor, color: textColor }}
      >
        {!!title && (
          <h2 className={classNames('subtitle title-decoration')}>{title}</h2>
        )}
        <HtmlRenderer html={content?.html} className="content" maxWidth={640} />
        {!!isTurbo360 && <Moto360 />}
      </div>
    </Wrapper>
  );
};

export default Polaroid;
