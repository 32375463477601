import styled from 'styled-components';

export const AccordionHeader = styled.button`
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  padding: 10px;
  padding-left: 0;
  background-color: #f7f7f7!important;
  text-align: left;
  transition: background-color .3s;
  &:hover {
    background-color: #e8e8e8!important;
  }
`;

export const AccordionContent = styled.div`
  overflow: hidden;
  transition: max-height 300ms;
  box-sizing: border-box;
  text-align: left;

  & > div {
    padding: 20px 0 10px 0;
  }
`;

export const AccordionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  border-left: 2px solid #c80f05;

  ${AccordionContent}, ${AccordionHeader} {
    padding-left: 15px;
  }
`;

export const SquareIcon = styled.div`
  position: relative;
  display: block;
  height: 20px;
  width: 20px;

  

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2px;
    left: 50%;
    margin-left: -1px;
    background: #c80f05;
    transition: top .3s, bottom .3s;
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 2px;
    margin-top: -1px;
    background: #c80f05;
  }

  &.open {
    &:before {
      top: 50%;
      bottom: 50%;
    }
  }
`;