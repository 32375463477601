import styled from 'styled-components';
import { device } from '../../utils';

export const Wrapper = styled.section`
  margin: 75px 0;

  &.mt-none {
    margin: 0 0;
  }

  &.mt-small {
    margin: 35px 0;
  }

  .bigtitle {
    margin-top: 0;
  }

  .product-item,
  .slide {
    /* margin: 0 5px; */

    @media ${device.laptop} {
      /* margin: 0 20px; */
    }
  }

  &.dark {
    background: #000;
    color: #fff;
    padding: 55px 0;

    .image {
      border: 1px solid #fff;
    }

    .title {
      color: #fff;

      &:after {
        filter: brightness(0) invert(1);
      }
    }
  }

  .swiper-slide {
    width: 300px;
    margin-right: 8px;
    box-sizing: border-box;
    /* padding-left: 20px;
    box-sizing: border-box;

    &:first-child {
      padding-left: 20px;
    }*/

    &:last-child {
      margin-right: 0;
    }


    

    @media ${device.tablet} {
      width: 300px;
      margin-right: 16px;

      &.product-item {
        width: 340px;
      }
    }

    @media ${device.laptop} {
      width: calc((100% - 60px) / 3);
      max-width: 586px;

      &.product-item {
        width: calc((100% - 60px) / 2.2);
      }
    }

    @media (min-width: 1440px) {
      width: 33.33%;
    }
  }

  &.turbo700 {
    .swiper-slide {
      img {
        transform: scale(0.6);
        transform-origin: center 91%;
      }

      @media (hover: hover) {
        &:hover .gatsby-image-wrapper img {
            transform: scale(.7);
        }
      }
    }
  }

  .swiper-button-disabled {
    display: none !important;
  }

  .swiper-button-next,
  .swiper-button-prev {
    display: none;

    &:after {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      border-radius: 35px;
      z-index: 1000;
      border: 0;
      min-width: 43px;
      min-height: 43px;
      opacity: 1;
      cursor: pointer;
      color: #000;
      font-size: 18px;
      font-weight: bold;
      transition: box-shadow 0.3s ease-in-out 0s, transform 0.3s ease-in-out 0s;
    }

    @media (hover: hover) {
      display: block;
      &:hover {
        &:after {
          box-shadow: rgb(0 0 0 / 20%) 0px 0px 6px;
          transform: scale(1.15);
        }
      }
    }
  }

  .swiper-button-prev {
    left: 4% !important;
    &:after {
      content: \E824;
    }
  }

  .swiper-button-next {
    right: 4% !important;
    &:after {
      content: \E825;
    }
  }

  .slider-container {
    position: relative;
    padding-left: 0;
    padding-right: 0;
    margin-top: 15px;

    @media ${device.laptop} {
      /* margin-top: 30px; */
    }

    @media (min-width: 1440px) {
      padding: 0 20px;
    }

    &.product {
      .swiper-button-next,
      .swiper-button-prev {
        margin-top: -6%;
      }
    }
  }

  .slider-container.product {
    .react-multiple-carousel__arrow {
      margin-top: -7%;
    }
  }
`;

export const AnimationVideo = styled.div`
  position: absolute;
  bottom: 27px;
  left: 20px;
  top: -31%;
  aspect-ratio: 1;
  z-index: 500;
  pointer-events: none;
  max-width: calc(100vw - 40px);


  video {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: bottom left;
  }
`;

export const SlideWrapper = styled.div`
  display: block;
  box-sizing: border-box;

  a {
    display: block;
  }

  .gatsby-image-wrapper {
    background: #f2f2f2;

    img {
      backface-visibility: hidden;
      transition: transform 0.3s ease-in-out, opacity 0.15s !important;
    }
  }

  .title {
    position: relative;
    font-family: 'Montserrat';
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    margin-top: 12px;

    &:after {
      display: inline-block;
      content: '>';
      margin-left: 10px;
      color: #e1251b;
      transform: scale(0.7, 1.3);
      transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    }
  }
  @media (hover: hover) {
    &:hover {
      .gatsby-image-wrapper img {
        transform: scale(1.1);
      }

      .title {
        &:after {
          transform: scaleX(0.7) scaleY(0.9) translateX(3px);
        }
      }
    }
  }
`;
