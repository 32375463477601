import React, { useRef, useState } from 'react';
import HtmlRenderer from '../../components/common/Html';

import {
  AccordionHeader,
  AccordionWrapper,
  AccordionContent,
  SquareIcon,
} from './FAQ.styles';

const Accordion = ({ section }) => {
  const [state, setState] = useState({
    show: false,
    height: 0,
  });
  const content = useRef();

  const toggle = () => {
    const newShow = !state.show;
    setState({
      show: newShow,
      height: newShow ? content.current.scrollHeight : 0,
    });
  }

  return (
    <AccordionWrapper itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
      <AccordionHeader
        onClick={toggle}
        className="clear"
        itemProp="name"
      >
        {section?.section_title}
        <SquareIcon
          className={state.show ? 'open' : ''}
        />
      </AccordionHeader>
      <AccordionContent
        ref={content}
        style={{
          maxHeight: `${state.height}px`,
        }}
        itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer"
      >
        <HtmlRenderer
          html={section?.content?.html}
          itemProp="text"
        />
      </AccordionContent>
    </AccordionWrapper>
  );
}
const PageFAQ = ({
  sections
}) => {
  if (!sections?.length) {
    return null;
  }
  return (
    <div itemScope itemType="https://schema.org/FAQPage">
      {sections.map((section, idx) => (
        <Accordion
          key={idx}
          section={section}
        />
      ))}
    </div>
  );
};

export default PageFAQ;
